<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID｛user.id｝" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户ID｛user.id｝" />
      </a-form-model-item>
      <a-form-model-item label="签证申请ID｛visa_apply.id｝" prop="applyId" >
        <a-input v-model="form.applyId" placeholder="请输入签证申请ID｛visa_apply.id｝" />
      </a-form-model-item>
      <a-form-model-item label="资料id{profile.id}" prop="profileId" >
        <a-input v-model="form.profileId" placeholder="请输入资料id{profile.id}" />
      </a-form-model-item>
      <a-form-model-item label="资料标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="类型 1-图文 2-cover letter 3-工作证明 4-行程单" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="是否已准备 0-否 1-是" prop="isReady" >
        <a-input v-model="form.isReady" placeholder="请输入是否已准备 0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="是否已读 0-否 1-是" prop="isRead" >
        <a-input v-model="form.isRead" placeholder="请输入是否已读 0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="是否已生成 0-否 1-是" prop="isGenerate" >
        <a-input v-model="form.isGenerate" placeholder="请输入是否已生成 0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApplyProfile, addApplyProfile, updateApplyProfile } from '@/api/visa/applyProfile'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        applyId: null,

        profileId: null,

        title: null,

        type: null,

        isReady: null,

        isRead: null,

        isGenerate: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户ID｛user.id｝不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        applyId: null,
        profileId: null,
        title: null,
        type: null,
        isReady: null,
        isRead: null,
        isGenerate: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApplyProfile({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApplyProfile(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addApplyProfile(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
