import request from '@/utils/request'


// 查询签证申请资料详情列表
export function listApplyProfile(query) {
  return request({
    url: '/visa/applyProfile/list',
    method: 'get',
    params: query
  })
}

// 查询签证申请资料详情分页
export function pageApplyProfile(query) {
  return request({
    url: '/visa/applyProfile/page',
    method: 'get',
    params: query
  })
}

// 查询签证申请资料详情详细
export function getApplyProfile(data) {
  return request({
    url: '/visa/applyProfile/detail',
    method: 'get',
    params: data
  })
}

// 新增签证申请资料详情
export function addApplyProfile(data) {
  return request({
    url: '/visa/applyProfile/add',
    method: 'post',
    data: data
  })
}

// 修改签证申请资料详情
export function updateApplyProfile(data) {
  return request({
    url: '/visa/applyProfile/edit',
    method: 'post',
    data: data
  })
}

// 删除签证申请资料详情
export function delApplyProfile(data) {
  return request({
    url: '/visa/applyProfile/delete',
    method: 'post',
    data: data
  })
}
